import { Value } from "@stringtale/react"
import { Routes, useRouterQuery } from "@blitzjs/next"
import { useMutation, useQuery } from "@blitzjs/rpc"
import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { FaExclamationTriangle } from "@react-icons/all-files/fa/FaExclamationTriangle"
import { TbArrowBigUpLineFilled } from "@react-icons/all-files/tb/TbArrowBigUpLineFilled"
import { TbEye } from "@react-icons/all-files/tb/TbEye"
import { TbEyeOff } from "@react-icons/all-files/tb/TbEyeOff"
import login from "app/auth/mutations/login"
import { Login } from "app/auth/validations"
import { Alert, FORM_ERROR, Form } from "app/core/components/Form"
import { LabeledTextField } from "app/core/components/LabeledTextField"
import Layout from "app/core/layouts/Layout"
import { AuthenticationError, PromiseReturnType } from "blitz"
import Image from "next/image"
import Link from "next/link"
import { useEffect, useId, useState } from "react"
import { Controller } from "react-hook-form"
import { DARKER_GREY_COLOR, DARK_BLUE_COLOR, ORANGE_COLOR } from "theme/colors"
import { poppins } from "theme/fonts"
import { PX14, PX16, PX18, PX24, PX28, PX32, PX40 } from "theme/sizes"
import Button from "ui/Button"
import Checkbox from "ui/Checkbox"
import Group from "ui/Group"
import Stack from "ui/Stack"
import Text from "ui/Text"
import Tooltip from "ui/Tooltip"
import { MainSchoolNotActiveError, UserNotActiveError } from "../errors"
import getSurfconextDomain from "../queries/getSurfconextDomain"
import { parseAsBoolean, useQueryState } from "nuqs"

type LoginFormProps = {
  onSuccess?: (user?: PromiseReturnType<typeof login>) => void
  hasMultipleSessionsError?: boolean | null
}

const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 1170px) {
    flex-direction: row;
    width: 100%;
    height: 100%;
    position: absolute;
  }
`

const LeftSide = styled.div`
  position: relative;
  width: 100%;
  box-sizing: border-box;
  padding: 30px 30px 50px;
  align-items: center;
  display: flex;

  @media (min-width: 1170px) {
    width: 40%;
    padding: 50px;
  }
`

const LoginFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  height: auto;
  justify-content: center;
  position: relative;
  width: 100%;
  max-width: 540px;
`

const RightSide = styled.div`
  background-image: url("https://storage.123zing.workers.dev/assets%2Finlogscherm.jpg?v=1724672136");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  padding: 150px 0;

  width: 100%;

  @media (max-width: 1169px) {
    min-height: 200px;
  }

  @media (min-width: 1170px) {
    padding: 0;
    width: 60%;
  }
`

const LoginVideoContainer = styled(Stack)`
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px 50px;
  box-sizing: border-box;
  gap: 24px;

  @media (min-width: 768px) {
    width: 70%;
  }

  @media (min-width: 1170px) {
    padding: 0 0 50px 0;
    width: 60%;
    min-width: 505px;
  }
`

const Title = styled.h1`
  font-family: ${poppins.style.fontFamily};
  font-weight: 900;
  font-size: ${PX40};
  color: ${DARK_BLUE_COLOR};
  margin-bottom: 22px;

  @media (max-height: 850px) {
    font-size: ${PX28};
    margin-bottom: 10px;
  }
`

const TitleRight = styled.h1`
  font-family: ${poppins.style.fontFamily};
  font-weight: 900;
  font-size: ${PX32};
  color: ${DARK_BLUE_COLOR};

  @media (max-height: 850px) {
    font-size: ${PX24};
    margin-bottom: 10px;
  }
`

const Intro = styled.p`
  font-family: ${poppins.style.fontFamily};
  font-size: ${PX18};
  color: ${DARK_BLUE_COLOR};
  margin-bottom: 43px;

  @media (max-height: 850px) {
    font-size: ${PX16};
    margin-bottom: 33px;
  }
`

const LinkStyle = css({
  fontFamily: poppins.style.fontFamily,
  fontSize: PX14,
  textDecoration: "underline",
  color: DARK_BLUE_COLOR,
  "&:hover": {
    color: ORANGE_COLOR,
  },
})

export const StyledLink = styled(Link)`
  ${LinkStyle}
`

const StyledA = styled.a`
  ${LinkStyle}
`

const RightLink = styled.a`
  color: inherit;
  text-decoration: underline;
`

const ActionIcon = styled(Button)`
  border-radius: 4px;
  padding: 5px;
  :hover {
    background-color: #e7e7e7;
  }
`

function useIsCapsActive() {
  const [isActive, setActive] = useState(false)
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.code === "CapsLock") {
        if (!!e.getModifierState && e.getModifierState("CapsLock")) {
          setActive(true)
        } else {
          setActive(false)
        }
      }
    }
    document.addEventListener("keydown", handleKeyDown)
    document.addEventListener("keyup", handleKeyDown)
    return () => {
      document.removeEventListener("keydown", handleKeyDown)
      document.removeEventListener("keyup", handleKeyDown)
    }
  })
  return isActive
}

export const LoginForm = (props: LoginFormProps) => {
  const [loginMutation] = useMutation(login)
  const query = useRouterQuery()
  const [surfConextDomain] = useQuery(getSurfconextDomain, undefined)
  const checkboxId = useId()
  const [isPasswordVisible, setShowPassword] = useState(false)

  const isCapsActive = useIsCapsActive()

  const [hasMultipleSessionsError] = useQueryState(
    "multipleSessions",
    parseAsBoolean
  )

  return (
    <Layout title="Login | 123ZING">
      <LoginContainer>
        <LeftSide>
          <LoginFormContainer>
            <div>
              <Image
                src={"/123Zing_logo.svg"}
                width={78}
                height={60}
                alt="Logo"
                style={{ marginBottom: 20 }}
              />
            </div>
            <Title>
              <Value name="apps.web.src.auth.components.loginform.inloggen_leerkracht">
                Inloggen - Leerkracht
              </Value>
            </Title>
            <Intro>
              <Value name="apps.web.src.auth.components.loginform.welkom_bij_123zing">
                Welkom bij 123ZING, hieronder kun je als leerkracht inloggen in
                de 123ZING leeromgeving.
              </Value>
            </Intro>

            {hasMultipleSessionsError && (
              <Alert role="alert" style={{ marginBottom: 20 }}>
                <FaExclamationTriangle
                  style={{ fontSize: 32, marginRight: 15 }}
                />
                <Value name="apps.web.src.auth.components.loginform.meerdere_sessies_error">
                  Je sessie is beëindigd. Mogelijk ben je ergens anders
                  ingelogd. Wil je hier inloggen? Dan wordt je andere sessie
                  beëindigd.
                </Value>
              </Alert>
            )}

            {query.authError && typeof query.authError === "string" && (
              <Alert role="alert" style={{ marginBottom: 20 }}>
                <FaExclamationTriangle
                  style={{ fontSize: 32, marginRight: 15 }}
                />
                <Value name="apps.web.src.auth.components.loginform.er_is_iets_misgegaan">
                  Er is iets misgegaan bij het inloggen met je studentaccount.
                  Probeer het opnieuw.
                </Value>
              </Alert>
            )}

            <Form
              submitText="Inloggen"
              schema={Login}
              initialValues={{ email: "", password: "", remember: false }}
              noValidate
              onSubmit={async (values) => {
                try {
                  const user = await loginMutation(values)
                  props.onSuccess?.(user)
                } catch (error: any) {
                  if (error instanceof AuthenticationError) {
                    // TODO update error based on what the issue was (Trial accounts)
                    return {
                      [FORM_ERROR]: (
                        <Value
                          name="apps.web.src.auth.components.loginform.error"
                          format={{
                            br: () => <br />,
                            rightlink1: (c) => (
                              <RightLink
                                as={Link}
                                {...Routes.ForgotPasswordPage()}
                              >
                                {c}
                              </RightLink>
                            ),
                          }}
                        >
                          {`E-mail/wachtwoord combinatie is ongeldig.
                          <br></br>
                          <rightlink1>
                            Vraag hier een nieuw wachtwoord aan.
                          </rightlink1>`}
                        </Value>
                      ),
                    }
                  } else if (error instanceof UserNotActiveError) {
                    return {
                      [FORM_ERROR]: (
                        <>
                          <Value name="apps.web.src.auth.components.loginform.je_account_is_nog_niet_geactiveerd">
                            {`Je account is nog niet geactiveerd.`}
                          </Value>
                          <RightLink as={Link} {...Routes.NewActivationPage()}>
                            <Value name="apps.web.src.auth.components.loginform.vraag_hier_een_nieuwe_activatielink_aan">
                              Vraag hier een nieuwe activatielink aan?
                            </Value>
                          </RightLink>
                        </>
                      ),
                    }
                  } else if (error instanceof MainSchoolNotActiveError) {
                    return {
                      [FORM_ERROR]:
                        "Je school is niet geactiveerd. Neem contact op met je beheerder",
                    }
                  } else {
                    return {
                      [FORM_ERROR]:
                        "Er is iets misgegaan met inloggen, probeer het nogmaals. - " +
                        error.toString(),
                    }
                  }
                }
              }}
            >
              <Stack gap="15px" style={{ marginBottom: "30px" }}>
                <LabeledTextField
                  name="email"
                  label={
                    <Value name="apps.web.src.auth.components.loginform.e_mailadres">
                      E-mailadres
                    </Value>
                  }
                  placeholder="E-mailadres"
                  type="email"
                />
                <LabeledTextField
                  name="password"
                  label={
                    <Value name="apps.web.src.auth.components.loginform.wachtwoord">
                      Wachtwoord
                    </Value>
                  }
                  placeholder="Wachtwoord"
                  type={isPasswordVisible ? "current-password" : "password"}
                  right={
                    <Group align="center">
                      {isCapsActive && (
                        <Tooltip
                          trigger={
                            <div>
                              <TbArrowBigUpLineFilled
                                size={20}
                                color={DARKER_GREY_COLOR}
                              />
                            </div>
                          }
                          content={
                            <Value name="apps.web.src.auth.components.loginform.caps_lock_staat_aan">
                              Caps lock staat aan
                            </Value>
                          }
                        />
                      )}
                      <Tooltip
                        trigger={
                          <ActionIcon
                            onClick={() => {
                              setShowPassword((s) => !s)
                            }}
                          >
                            {isPasswordVisible ? (
                              <TbEyeOff size={20} color={DARK_BLUE_COLOR} />
                            ) : (
                              <TbEye size={20} color={DARK_BLUE_COLOR} />
                            )}
                          </ActionIcon>
                        }
                        content={
                          <Value name="apps.web.src.auth.components.loginform.toon_wachtwoord">
                            Toon wachtwoord
                          </Value>
                        }
                      />
                    </Group>
                  }
                />
                <StyledLink {...Routes.ForgotPasswordPage()}>
                  <Value name="apps.web.src.auth.components.loginform.wachtwoord_vergeten">
                    Wachtwoord vergeten
                  </Value>
                </StyledLink>
                <Group gap="10px" align="center" style={{ width: "100%" }}>
                  <Controller
                    name="remember"
                    render={({ field }) => (
                      <Checkbox id={checkboxId} {...field} />
                    )}
                  />
                  <label htmlFor={checkboxId}>
                    <Text size={PX14}>
                      <Value name="apps.web.src.auth.components.loginform.mijn_inloggevens_onthouden">
                        Mijn inloggevens onthouden
                      </Value>
                    </Text>
                  </label>
                </Group>
              </Stack>
            </Form>
            <Group gap="32px" style={{ marginTop: 20, textAlign: "center" }}>
              <StyledA
                href="https://123zing.my.site.com/knowledgebase/s/article/wat-kun-je-doen-als-je-niet-kunt-inloggen"
                target="_blank"
                rel="noreferrer"
              >
                <Value name="apps.web.src.auth.components.loginform.hulp_bij_inloggen">
                  Hulp bij inloggen
                </Value>
              </StyledA>
              <StyledLink {...Routes.NewActivationPage()}>
                <Value name="apps.web.src.auth.components.loginform.nieuwe_activatielink">
                  Nieuwe activatielink
                </Value>
              </StyledLink>
              <StyledA href={`/api/auth/${surfConextDomain}`}>
                <Value name="apps.web.src.auth.components.loginform.inloggen_student_docent">
                  Inloggen student / docent
                </Value>
              </StyledA>
            </Group>
            {/* <LineSeparatorContainer>
              <LineSeparatorWithText text="Wil je inloggen op de kidspagina?" />
            </LineSeparatorContainer>

            <KidsLoginButton href="https://kids.123zing.nl/" target="_self">
              <KidsLoginButtonImage
                src={"/kids-login-link-img.png"}
                alt=""
                width={124}
                height={93}
              ></KidsLoginButtonImage>
              <Stack
                style={{ marginRight: 15, alignItems: "flex-start", gap: 2 }}
              >
                <KidsLoginTitle>Inloggen op de kidspagina</KidsLoginTitle>
                <KidsLoginText>
                  Log in als leerling op de 123ZING Kidspagina.
                </KidsLoginText>
              </Stack>
              <KidsLoginChevron className="chevron"></KidsLoginChevron>
            </KidsLoginButton> */}
          </LoginFormContainer>
        </LeftSide>
        <RightSide>
          {/* <LoginInfoBox>
            <LoginInfoBoxTitle>Meer informatie?</LoginInfoBoxTitle>
            <LoginInfoBoxLink href="https://www.123zing.nl/scholing">
              Bekijk het 123ZING scholingsaanbod!
            </LoginInfoBoxLink>
          </LoginInfoBox> */}
        </RightSide>
      </LoginContainer>
    </Layout>
  )
}

export default LoginForm
