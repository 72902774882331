import { zodResolver } from "@hookform/resolvers/zod"

import { PropsWithoutRef, ReactNode, useState } from "react"
import {
  FieldNamesMarkedBoolean,
  FormProvider,
  UseFormProps,
  useForm,
} from "react-hook-form"
import { z } from "zod"
import styled from "@emotion/styled"
import { FaExclamationTriangle } from "@react-icons/all-files/fa/FaExclamationTriangle"
import { FieldsError } from "admin/src/FieldError"
import {
  DARK_ORANGE_COLOR,
  DARK_YELLOW_COLOR,
  ORANGE_COLOR
} from "theme/colors"
import { poppins } from "theme/fonts"
import { PX16 } from "theme/sizes"
import Button from "ui/Button"
import Group from "ui/Group"
import Stack from "ui/Stack"

export interface FormProps<S extends z.ZodType<any, any>>
  extends Omit<PropsWithoutRef<JSX.IntrinsicElements["form"]>, "onSubmit"> {
  /** All your form fields */
  children?: ReactNode
  /** Text to display in the submit button */
  submitText?: string
  schema?: S
  onSubmit: (
    values: z.infer<S>,
    initialValues: any
  ) => Promise<void | OnSubmitResult>
  initialValues?: UseFormProps<z.infer<S>>["defaultValues"]
  onClose?: (isDirty: boolean) => void
  closeText?: string
}

interface OnSubmitResult {
  FORM_ERROR?: ReactNode
  [prop: string]: any
}

export const FORM_ERROR = "FORM_ERROR"

export const Alert = styled.div`
  border-radius: 6px;
  background-color: ${DARK_ORANGE_COLOR};
  width: 100%;
  color: white;
  display: inline-flex;
  padding: 21px 16px 17px;
  margin-bottom: 12px;
  font-family: ${poppins.style.fontFamily};
  font-weight: 600;
  font-size: ${PX16};
  line-height: 32px;
  box-sizing: border-box;
`

const SubmitButton = styled(Button)`
  background-color: ${ORANGE_COLOR};
  height: 50px;
  color: #ffffff;
  font-weight: bold;
  font-family: ${poppins.style.fontFamily};
  border-radius: 25px;
  font-size: ${PX16};
  text-align: center;
  width: 100%;
  outline: transparent;
  &:hover {
    background-color: ${DARK_YELLOW_COLOR};
  }
  &:disabled {
    opacity: 1;
    background-color: ${ORANGE_COLOR};
    &:hover {
      background-color: ${DARK_YELLOW_COLOR};
    }
  }
`

export function Form<S extends z.ZodType<any, any>>({
  children,
  submitText,
  schema,
  initialValues,
  onSubmit,
  onClose,
  closeText,
  ...props
}: FormProps<S>) {
  const ctx = useForm<z.infer<S>>({
    // mode: "onBlur",
    resolver: schema ? zodResolver(schema) : undefined,
    defaultValues: initialValues,
  })
  const [formError, setFormError] = useState<ReactNode | null>(null)
  const { isDirty, dirtyFields, isSubmitting } = ctx.formState

  return (
    <FormProvider {...ctx}>
      <form
        onSubmit={ctx.handleSubmit(
          async (values) => {
            try {
              const result = (await onSubmit(values, dirtyFields)) || {}
              for (const [key, value] of Object.entries(result)) {
                if (key === FORM_ERROR) {
                  setFormError(value)
                } else {
                  ctx.setError(key as any, {
                    type: "submit",
                    message: value,
                  })
                }
              }
            } catch (e) {
              if (e instanceof FieldsError) {
                for (const field of e.fields) {
                  ctx.setError(field as any, {
                    type: "submit",
                    message: e.message,
                  })
                }
              } else {
                // console.log("onError", e, e instanceof FieldError)
                // setFormError(e.toString())
              }
            }
          },
          (err) => {
            console.log("onError", err)
          }
        )}
        className="form"
        {...props}
      >
        <Stack>
          {/* Form fields supplied as children are rendered here */}
          {formError && (
            <Alert role="alert">
              <FaExclamationTriangle
                style={{ fontSize: 32, marginRight: 15 }}
              />
              <div>{formError}</div>
            </Alert>
          )}

          {children}

          <Group>
            {submitText && (
              <SubmitButton type="submit" disabled={isSubmitting || !isDirty}>
                {submitText}
              </SubmitButton>
            )}
          </Group>
        </Stack>
      </form>
    </FormProvider>
  )
}

export default Form
