
import styled from '@emotion/styled'
import { Provider, Root, Trigger, Portal, Content, Arrow } from '@radix-ui/react-tooltip';
import { DIMMED_BLUE_COLOR } from 'theme/colors'
import { poppins } from 'theme/fonts'

const TooltipContent = styled(Content)`
  background-color: ${DIMMED_BLUE_COLOR};
  padding: 9px 19px;
  box-shadow: 0px 3px 6px #0000004D;
  border-radius: 4px;
  font-family: ${poppins.style.fontFamily};
  color: white;
`

export const TooltipArrow = styled(Arrow)`
  fill: ${DIMMED_BLUE_COLOR};
`

export default function Tooltip({
  trigger,
  content,
  side,
}: {
  trigger
  content
  side?: "left" | "right" | "bottom" | "top"
}) {
  return (
    <Provider delayDuration={0}>
      <Root>
        <Trigger asChild>{trigger}</Trigger>
        <Portal>
          <TooltipContent sideOffset={5} side={side ? side : "top"}>
            {content}
            <TooltipArrow />
          </TooltipContent>
        </Portal>
      </Root>
    </Provider>
  )
}
