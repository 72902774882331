import styled from "@emotion/styled"
import { ComponentProps } from "react"
import { useFormContext } from "react-hook-form"
import { DARK_BLUE_COLOR, RED_COLOR } from "theme/colors"
import { poppins } from "theme/fonts"
import { PX16 } from "theme/sizes"
import Input from "ui/Input"
import Stack from "ui/Stack"

export interface LabeledTextFieldProps {
  name: string
  label: React.ReactNode
  placeholder?: string
  type?: string
}

const Label = styled.label`
  font-family: ${poppins.style.fontFamily};
  font-weight: bold;
  font-size: ${PX16};
  color: ${DARK_BLUE_COLOR};
  margin-bottom: 4px;
`

const ErrorMessage = styled.p`
  color: ${RED_COLOR};
  margin-top: 5px;

`

export const LabeledTextField = ({
  name,
  placeholder,
  label,
  type,
  style,
  ...props
}: LabeledTextFieldProps & {style?: React.CSSProperties} & ComponentProps<typeof Input>) => {
  const {
    register,
    formState: { isSubmitting, errors },
  } = useFormContext()

  const error = errors[name]

  const errorMessage = Array.isArray(error)
    ? error.join(", ")
    : error?.message || ""

  return (
    <Stack style={style}>
      <Label>{label}</Label>
      <Input
        placeholder={placeholder}
        disabled={isSubmitting}
        type={type}
        {...props}
        {...register(name)}
      />
      {errorMessage && <ErrorMessage>{errorMessage as string}</ErrorMessage>}
    </Stack>
  )
}

export default LabeledTextField
