import SuperJson from "superjson"

export class FieldsError extends Error {
  name = "FieldError"
  fields: string[]

  constructor(fields: string[], message: string) {
    super()
    this.fields = fields
    this.message = message
  }
}
// Register with SuperJson serializer so it's reconstructed on the client
SuperJson.registerClass(FieldsError, {
  identifier: "FieldsError",
})
SuperJson.allowErrorProps("fields", "message")
