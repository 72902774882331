import { z } from "zod"

export const email = z
  .string()
  .toLowerCase()
  .trim()
  .email("Vul een geldig e-mailadres in")
  
export const password = z
  .string()
  .trim()
  .min(
    10,
    "Het wachtwoord moet minimaal 10 tekens lang zijn. Tip: sterke wachtwoorden hebben minimaal 12 tekens."
  )
  .max(100)

export const Signup = z.object({
  email,
  password,
})

export const Login = z.object({
  email,
  password: z.string(),
  remember: z.boolean(),
})

export const ForgotPassword = z.object({
  email,
})

export const NewActivation = z.object({
  email,
})

export const ActivateAccount = z
  .object({
    password: password,
    passwordConfirmation: password,
    token: z.string(),
  })
  .refine((data) => data.password === data.passwordConfirmation, {
    message:
      "De wachtwoorden komen niet overeen. Zorg dat beide wachtwoorden hetzelfde zijn.", // WW matcht niet aanpassen
    path: ["passwordConfirmation"], // set the path of the error
  })

export const ResetPassword = z
  .object({
    password: password,
    passwordConfirmation: password,
    token: z.string(),
  })
  .refine((data) => data.password === data.passwordConfirmation, {
    message:
      "De wachtwoorden komen niet overeen. Zorg dat beide wachtwoorden hetzelfde zijn.", // WW matcht niet aanpassen
    path: ["passwordConfirmation"], // set the path of the error
  })

export const ChangePassword = z
  .object({
    currentPassword: z.string(),
    newPassword: password,
    newPasswordConfirmation: password,
  })
  .refine((data) => data.newPassword === data.newPasswordConfirmation, {
    message:
      "De wachtwoorden komen niet overeen. Zorg dat beide wachtwoorden hetzelfde zijn.", // WW matcht niet aanpassen
    path: ["newPasswordConfirmation"], // set the path of the error
  })
